import React, { CSSProperties, memo, useMemo } from "react"

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useMeasure } from "react-use"
import { ProductCatalogItem } from "~/src/serializedRecords/productCatalogItem"
import { CatalogProductTile } from "./CatalogProductTile"

//region Types
export type CatalogItemGridProps = {
  items: ProductCatalogItem[]
  onLoveProduct?: (item: ProductCatalogItem, index: number) => void
  onHideProduct?: (item: ProductCatalogItem, index: number) => void
  onAddToProjectRequest?: (item: ProductCatalogItem, index: number) => void
}
//endregion

//region Component
export const CatalogItemGrid = memo(function CatalogItemGrid(props: CatalogItemGridProps) {
  const { items, onLoveProduct, onHideProduct, onAddToProjectRequest } = props
  // `baseWidth` is used as the "attempted" width of each column in the grid.
  const baseWidth = 230
  const minColLength = 2
  const itemCount = items.length

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const maxColLength = Math.floor(width / baseWidth)

  const colLength = useMemo(() => Math.max(minColLength, maxColLength), [maxColLength])

  return (
    <>
      {itemCount === 0 ? (
        <div className="flex justify-center mx-auto min-h-64">
          <EmptyItemGrid />
        </div>
      ) : (
        <div
          className="grid grid-cols-[repeat(var(--col-length),1fr)] w-full"
          style={{ "--col-length": colLength } as CSSProperties}
          ref={ref}
        >
          {items.map((item, index) => {
            const { productId: id, ...rest } = item
            return (
              <div key={id} className="flex items-center justify-center p-1">
                <CatalogProductTile
                  id={id}
                  onLove={() => onLoveProduct?.(item, index)}
                  onHide={() => onHideProduct?.(item, index)}
                  onAddToProjectRequest={() => onAddToProjectRequest?.(item, index)}
                  {...rest}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
})
//endregion

function EmptyItemGrid() {
  return (
    <div className="flex flex-col justify-center items-center p-8">
      <MagnifyingGlassIcon className="h-14 w-14 text-gray-300" />
      <h1 className="text-base font-medium leading-6 text-center pt-4">We couldn’t find what you were looking for</h1>
      <p className="text-sm text-center">Make sure all words are spelled correctly or try different search terms.</p>
    </div>
  )
}
