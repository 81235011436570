import React from "react"

import { twMerge } from "tailwind-merge"
import { dataFlag } from "~/src/lib/jsx"

export type TabProps = {
  selected?: boolean | (() => boolean)
  className?: string
  value?: string
  type?: "button"
  onClick?: React.MouseEventHandler<HTMLButtonElement>
} & Omit<React.AnchorHTMLAttributes<HTMLButtonElement>, "type">

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(function TabLink(props, ref) {
  const { className, selected = false, ...restProps } = props

  return (
    <button
      className={twMerge(
        "flex py-2 text-gray-500 text-nowrap transition-colors",
        "data-[selected]:text-gray-900 data-[selected]:border-b data-[selected]:border-gray-900",
        className
      )}
      ref={ref}
      {...dataFlag(typeof selected === "boolean" ? selected : selected(), "selected")}
      {...restProps}
    />
  )
})

Tab.displayName = "Tab"
