import React, { useContext, useState, useEffect, useMemo, PropsWithChildren } from "react"
import { Bee } from "~/src/components/BeeKit"
import { twMerge } from "tailwind-merge"

type ContextType = {
  setProvidedImages?: (children: React.ReactNode) => void
  setProvidedThumbnails?: (children: React.ReactNode) => void
}
const ImageCarouselContext = React.createContext<ContextType>({})

export function useImageCarouselContext() {
  const context = useContext(ImageCarouselContext)

  if (context == null) {
    throw new Error("Image Carousel components must be wrapped in <ImageCarousel />")
  }

  return context
}

export type ImageCarouselOptions = {
  selectedIndex?: number
  defaultSelectedIndex?: number
  onSelectIndex?: (index: number) => void
  className?: string
  imageContainerClassName?: string
  thumbnailContainerClassName?: string
  thumbnailClassName?: string
  selectedThumbnailClassName?: string
}
export type ImageCarouselProps = { children?: React.ReactNode } & ImageCarouselOptions

export function ImageCarousel(props: ImageCarouselProps) {
  const {
    children,
    selectedIndex,
    defaultSelectedIndex,
    onSelectIndex,
    className,
    imageContainerClassName,
    thumbnailContainerClassName,
    thumbnailClassName,
    selectedThumbnailClassName,
  } = props

  const [providedImages, setProvidedImages] = useState<React.ReactNode>(null)
  const [providedThumbnails, setProvidedThumbnails] = useState<React.ReactNode>(null)
  const [currentlySelectedIndex, innerSetCurrentlySelectedIndex] = useState<number>(defaultSelectedIndex || 0)

  const setCurrentlySelectedIndex = (index: number) => {
    innerSetCurrentlySelectedIndex(index)
    onSelectIndex?.(index)
  }

  useEffect(() => {
    if (selectedIndex != null && selectedIndex != currentlySelectedIndex) {
      innerSetCurrentlySelectedIndex(selectedIndex)
    }
  }, [selectedIndex])

  const providerValue = useMemo(
    () => ({
      setProvidedImages,
      setProvidedThumbnails,
    }),
    [setProvidedImages, setProvidedThumbnails]
  )

  return (
    <>
      <ImageCarouselContext.Provider value={providerValue}>{children}</ImageCarouselContext.Provider>
      <div className={twMerge("flex flex-col items-center", className)}>
        <Bee.Carousel
          className={imageContainerClassName || ""}
          value={currentlySelectedIndex}
          onValueChange={setCurrentlySelectedIndex}
          showDots={false}
          showArrows={true}
        >
          {providedImages}
        </Bee.Carousel>
        <div className={twMerge("flex gap-4 justify-center", thumbnailContainerClassName)}>
          {React.Children.map(providedThumbnails, (child, index) => {
            return (
              <div
                tabIndex={0}
                className={
                  index == currentlySelectedIndex
                    ? twMerge(
                        "cursor-pointer w-14 h-14",
                        thumbnailClassName,
                        "rounded-lg border border-navy-800",
                        selectedThumbnailClassName
                      )
                    : twMerge("cursor-pointer w-14 h-14", thumbnailClassName)
                }
                onClick={() => setCurrentlySelectedIndex(index)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    return setCurrentlySelectedIndex(index)
                  }
                }}
              >
                {child}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

ImageCarousel.Images = function Images(props: PropsWithChildren<object>) {
  const { children } = props
  const { setProvidedImages } = useImageCarouselContext()

  useEffect(() => {
    if (setProvidedImages) {
      setProvidedImages(children)
    }
  }, [children, setProvidedImages])

  return null
}

ImageCarousel.Thumbnails = function Thumbnails(props: PropsWithChildren<object>) {
  const { children } = props
  const { setProvidedThumbnails } = useImageCarouselContext()

  useEffect(() => {
    if (setProvidedThumbnails) {
      setProvidedThumbnails(children)
    }
  }, [children, setProvidedThumbnails])

  return null
}
