import React, { useEffect, useMemo, useRef, useState } from "react"

import { HeartIcon, NoSymbolIcon } from "@heroicons/react/24/outline"
import { isEqual, snakeCase } from "lodash-es"
import qs from "qs"
import {
  Form,
  ScrollRestoration,
  useLoaderData,
  useRevalidator,
  useSearchParams,
  useSubmit,
  Outlet,
} from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { PagyNav } from "~/src/components"
import { Bee } from "~/src/components/BeeKit"
import { SortMenu, SortOption } from "~/src/components/Catalog/SortMenu"
import { iClient } from "~/src/lib/appClients"
import { iname } from "~/src/lib/iname"
import { dataFlag } from "~/src/lib/jsx"
import { Pagy, ProductCatalogItem } from "~/src/serializedRecords"
import { Range } from "~/src/types/filter"
import { CatalogFilter, FilterValues } from "./CatalogFilter"
import { CatalogFilterToggle } from "./CatalogFilterToggle"
import { CatalogItemGrid } from "./CatalogItemGrid"

// The breakpoint we're using for is sm: in Tailwind
const FILTER_BREAKPOINT = 640
const qName = iname().q

//region Types
export type ProductCatalogContentProps = {
  meta: Pagy
  categories: string[]
  productFeatures: string[]
  minPrice: number
  maxPrice: number
  items: ProductCatalogItem[]
}
//endregion

//region Component
export function ProductCatalogContent() {
  const data = useLoaderData() as ProductCatalogContentProps
  const revalidator = useRevalidator()
  const [searchParams] = useSearchParams()
  const submit = useSubmit()
  const tabInputRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState<string>(searchParams.get("search") ?? "")

  const [defaultFilters, sortValue] = useMemo(() => selectedFiltersFromSearchParams(searchParams), [searchParams])

  useEffect(() => {
    if (searchValue != searchParams.get("search")) {
      setSearchValue(searchParams.get("search") ?? "")
    }
  }, [searchParams])

  const {
    items,
    meta: { pages, count, next, previous, series = [] },
    categories,
    productFeatures,
    minPrice = 0,
    maxPrice = 1000,
  } = data

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(window.innerWidth >= FILTER_BREAKPOINT)
  const emptyFilters = {
    categories: [],
    productFeatures: [],
    minimumQuantities: [],
    priceRange: { min: minPrice, max: maxPrice },
  }

  const initialFilters = useMemo(
    () => ({
      ...emptyFilters,
      ...defaultFilters,
    }),
    [defaultFilters]
  )

  const [selectedFilters, setSelectedFilters] = useState<FilterValues>(() => initialFilters)

  const filterDisabled = useMemo(() => isEqual(selectedFilters, initialFilters), [selectedFilters, initialFilters])

  const formRef = useRef<HTMLFormElement>(null)
  //endregion

  const selectedFiltersLength = useMemo(
    () =>
      Object.keys(selectedFilters)
        .map((key) => {
          const value = selectedFilters[key]
          switch (key) {
            case "priceRange": {
              if (value.min === minPrice && value.max === maxPrice) {
                return 0
              } else {
                return 1
              }
            }
            default: {
              return value.length
            }
          }
        })
        .reduce((acc, value) => acc + value, 0),
    [selectedFilters]
  )

  const selectedFilterNames = useMemo(
    () => filterNamesFromSelections(selectedFilters, minPrice, maxPrice),
    [selectedFilters]
  )

  //region Event Handlers
  const handleClearAll = () => {
    setSelectedFilters(emptyFilters)

    requestAnimationFrame(() => submit(formRef.current))
  }

  const handleLoveProduct = async (item: ProductCatalogItem) => {
    await iClient.put(`/product_preferences/${item.productId}/toggle/love`)

    // Forces a refresh of loader data.
    revalidator.revalidate()
  }

  const handleHideProduct = async (item: ProductCatalogItem) => {
    await iClient.put(`/product_preferences/${item.productId}/toggle/hidden`)

    // Forces a refresh of loader data.
    revalidator.revalidate()
  }

  const handleOnTabClick = (key: string) => () => {
    if (!tabInputRef.current) return

    tabInputRef.current.value = key

    if (!formRef.current) return

    const formData = new FormData(formRef.current)
    formData.delete("search")

    submit(formData)
  }
  //endregion

  //region JSX
  return (
    <div className="flex flex-col mx-6">
      <ScrollRestoration />

      <Form ref={formRef} method="get" className="contents">
        <Bee.Searchbar
          name="search"
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          onClear={() => {
            setSearchValue("")

            if (!formRef.current) return

            const formData = new FormData(formRef.current)
            formData.delete("search")

            submit(formData)
          }}
          className="w-full mt-8"
        />

        <Bee.HiddenInput name="tab" ref={tabInputRef} defaultValue={searchParams.get("tab") ?? "all_products"} />

        {selectedFilterNames.map(({ key, name, value }) => (
          <Bee.HiddenInput key={key} value={value} name={name} />
        ))}
      </Form>

      <Bee.TabBar className="mt-2">
        {[
          ["All Products"],
          ["Favorites", HeartIcon, "h-4 w-4 data-[selected]:text-magenta-500 data-[selected]:fill-current"],
          ["Hidden", NoSymbolIcon, "h-4 w-4"],
          ["Past Orders"],
        ].map(([label, Icon, className]: [string, ...any]) => {
          const key = snakeCase(label)
          const isSelected = (searchParams.get("tab") ?? "all_products") === key

          return (
            <Bee.Tab type="button" key={key} onClick={handleOnTabClick(key)} selected={isSelected}>
              <div className="flex items-center gap-1">
                {Icon && <Icon className={className} {...dataFlag(isSelected, "selected")} />}
                {[label, isSelected && `(${count})`].filter(Boolean).join(" ")}
              </div>
            </Bee.Tab>
          )
        })}
      </Bee.TabBar>

      <div className="h-full">
        <div className="flex items-center justify-between w-full mt-8">
          <CatalogFilterToggle
            className="pb-2"
            mobileClassName={twMerge(
              "fixed bottom-4 right-1/2 z-30 h-10 w-20 -translate-x-px",
              selectedFiltersLength > 0 && "px-12"
            )}
            open={isFilterOpen}
            numSelectedFilters={selectedFiltersLength}
            onClearAll={handleClearAll}
            onToggleFilter={() => setIsFilterOpen(!isFilterOpen)}
          />
          <SortMenu
            mobileClassName="fixed bottom-4 left-1/2 translate-x-px z-10 h-10 w-20"
            value={sortValue}
            onSelect={(option) => {
              if (!formRef.current) return
              const formData = new FormData(formRef.current)
              const { field, direction } = option

              formData.set(qName.sort[0]._, field)
              formData.set(qName.sort[1]._, direction)

              submit(formData)
            }}
          />
        </div>

        <div className="flex w-full">
          <CatalogFilter
            disabled={filterDisabled}
            open={isFilterOpen}
            numSelectedFilters={selectedFiltersLength}
            priceRangeBounds={{ min: minPrice, max: maxPrice }}
            values={selectedFilters}
            options={{ categories, productFeatures }}
            onChange={(values) => setSelectedFilters(values)}
            onApply={() => submit(formRef.current)}
            onClearAll={handleClearAll}
            onClose={() => isFilterOpen && setIsFilterOpen(false)}
          />

          <div className="flex flex-col w-full">
            <CatalogItemGrid onLoveProduct={handleLoveProduct} onHideProduct={handleHideProduct} items={items} />
            {series.length > 0 && pages > 1 && (
              <div className="flex justify-center p-8 mb-10 sm:mb-0">
                <PagyNav previous={previous} next={next} series={series} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  )
  //endregion
}
//endregion

//region Private
function filterNamesFromSelections(
  selectedFilters: FilterValues,
  minPrice: number,
  maxPrice: number
): { key: string; name: string; value: string }[] {
  return Object.keys(selectedFilters)
    .flatMap((key) => {
      const value = selectedFilters[key]
      switch (key) {
        case "priceRange": {
          if (value.min === minPrice && value.max === maxPrice) {
            return []
          } else {
            return [
              ["pr", "min", value.min.toString()],
              ["pr", "max", value.max.toString()],
            ]
          }
        }
        case "minimumQuantities": {
          return value.flatMap((value: Range, index: number) => [
            ["mqs", index, "min", value.min.toString()],
            ["mqs", index, "max", value.max.toString()],
          ])
        }
        case "categories": {
          return value.map((value: string, index: number) => ["cs", index, value])
        }
        case "productFeatures": {
          return value.map((value: string, index: number) => ["pfs", index, value])
        }
      }
    })
    .map((pieces: string[]) => ({
      key: pieces.join("."),
      name: pieces
        .slice(0, -1)
        .reduce((acc, piece) => acc[piece], qName)
        .toString(),
      value: pieces.at(-1),
    }))
    .filter((piece) => piece.value != null) as { key: string; name: string; value: string }[]
}

type CatalogSearchParams = {
  q?: {
    /** Maps to `categories` */
    cs?: string[]
    /** Maps to `productFeatures` */
    pfs?: string[]
    /** Maps to `minimumQuantities` */
    mqs?: { min: string; max: string }[]
    /** Maps to `priceRange` */
    pr?: { min: string; max: string }
    /** Maps to `sort` */
    sort?: [string, string]
  }
}

/**
 * Parses the given search parameters and returns the default selected filters.
 *
 * @param searchParams - The URLSearchParams object containing the search parameters.
 * @returns The default selected filters as an object of type FilterValues.
 */
function selectedFiltersFromSearchParams(searchParams: URLSearchParams): [FilterValues, SortOption | undefined] {
  const parsedParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true }) ?? {}
  const { q: { cs, pfs, mqs, pr, sort } = {} }: CatalogSearchParams = parsedParams

  const filters = {} as FilterValues
  if (Array.isArray(cs)) {
    filters.categories = Array.from(new Set(cs.filter((x) => typeof x === "string")))
  }

  if (Array.isArray(pfs)) {
    filters.productFeatures = Array.from(new Set(pfs.filter((x) => typeof x === "string")))
  }

  if (Array.isArray(mqs)) {
    const minimumQuantities = mqs
      .filter((x) => typeof x === "object")
      .map((range: { min: string; max: string }) => {
        const min = parseInt(range.min)
        const max = parseInt(range.max)

        if (min > 0 && max > min) {
          return { min, max }
        }
      })
      .filter((x) => x != null)

    if (minimumQuantities.length > 0) {
      filters.minimumQuantities = minimumQuantities
    }
  }

  if (
    // 🤦
    typeof pr === "object" &&
    "min" in pr &&
    "max" in pr &&
    typeof pr.min === "string" &&
    typeof pr.max === "string"
  ) {
    filters.priceRange = {
      min: parseInt(pr.min),
      max: parseInt(pr.max),
    }
  }

  let sortOption: SortOption | undefined

  if (Array.isArray(sort)) {
    const [field, direction] = sort
    sortOption = {
      field,
      direction: direction === "asc" ? "asc" : "desc",
    }
  }

  return [filters, sortOption]
}
//endregion
