import { ArrowRightIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"
import React, { ChangeEvent, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

export type SearchbarProps = {
  defaulValue?: string
  className?: string
  name?: string
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClear?: (element: HTMLInputElement) => void
}

export function Searchbar(props: SearchbarProps) {
  const { className, onChange, onClear, defaulValue, name } = props

  const [value_, setValue] = useState(defaulValue)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const isControlled = "value" in props
  const value = isControlled ? props.value : value_

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isControlled) setValue(event.target.value)

    onChange?.(event)
  }

  const handleClear = () => {
    if (!isControlled) setValue("")
    if (!inputRef.current) return

    onClear?.(inputRef.current)
    inputRef?.current?.focus()
  }

  return (
    <div
      className={twMerge(
        `flex items-center w-full h-[48px] bg-white py-2 pl-4 pr-4 border border-gray-300 rounded-lg`,
        `ring-blue-600 focus-within:border-blue-600 focus-within:ring-1`,
        className
      )}
    >
      <MagnifyingGlassIcon
        className="text-gray-400 h-6 w-6 flex-shrink-0 cursor-pointer"
        onClick={() => {
          setIsFocused(true)
          inputRef.current?.focus()
        }}
      />
      <input
        name={name}
        type="text"
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={isFocused ? "" : "Search"}
        className="flex-grow min-w-0 bg-inherit placeholder-gray-500 outline-none border-none ring-0 focus:outline-none focus:ring-0"
        ref={inputRef}
      />
      {value && !isFocused && (
        <button type="button" className="text-gray-500" onClick={handleClear}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      )}
      {isFocused && (
        <div className="flex items-center leading-5 text-gray-500 gap-1">
          <span className="hidden sm:inline">Hit &quot;Enter&quot; to Search</span>
          <span className="inline sm:hidden">Go</span>
          <ArrowRightIcon className="h-6 w-6" />
        </div>
      )}
    </div>
  )
}
