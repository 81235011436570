import React from "react"
import { Link, LinkProps } from "react-router-dom"

import { twMerge } from "tailwind-merge"
import { dataFlag } from "~/src/lib/jsx"

export type TabLinkProps = {
  selected?: boolean | (() => boolean)
  className?: string
  to?: LinkProps["to"]
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const TabLink = React.forwardRef<HTMLAnchorElement, TabLinkProps>(function TabLink(props, ref) {
  const { className, selected = false, to = "", ...restProps } = props

  return (
    <Link
      className={twMerge(
        "flex py-2 text-gray-500 text-nowrap transition-colors",
        "data-[selected]:text-gray-900 data-[selected]:border-b data-[selected]:border-gray-900",
        className
      )}
      to={to}
      ref={ref}
      {...dataFlag(typeof selected === "boolean" ? selected : selected(), "selected")}
      {...restProps}
    />
  )
})
