import { ReactComponent } from "~/src/customElements/reactComponent"
import { isNil } from "./any"

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __reactComponents: Record<string, AnyComponent<any>>
  }
}

export type AnyComponent<P extends object> = React.ComponentType<P> | React.LazyExoticComponent<React.ComponentType<P>>

/**
 * Register a React component to be usable with `react_component` helper from React-on-Rails
 * @param components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function registerComponents(components: Record<string, AnyComponent<any>>) {
  window.__reactComponents ||= {}
  Object.assign(window.__reactComponents, components)

  customElements.get("react-component") || customElements.define("react-component", ReactComponent)
}

export function getComponent<P extends object>(name: string): AnyComponent<P> {
  const component = window.__reactComponents[name]

  if (isNil(component)) throw new Error(`${name} component must be registered`)

  return component
}
