import React, { useState, useRef, useEffect, useMemo } from "react"
import { Bee } from "~/src/components/BeeKit"
import { Menu, MenuButton, Radio, RadioGroup } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { twMerge } from "tailwind-merge"
import { humanize } from "underscore.string"

//region Types
export type SortMenuProps = {
  defaultValue?: SortOption
  isOpen?: boolean
  onClose?: () => void
  onSelect?: (option: SortOption) => void
  value?: SortOption
  className?: string
  mobileClassName?: string
  sortByRef?: React.RefObject<HTMLDivElement>
}

export type SortOption = {
  label?: string
  field: string
  direction: "asc" | "desc"
}
//endregion

const options: SortOption[] = [
  { label: "Newest", field: "created_at", direction: "desc" },
  { label: "Oldest", field: "created_at", direction: "asc" },
  { label: "Price: Low to High", field: "low_price", direction: "asc" },
  { label: "Price: High to Low", field: "low_price", direction: "desc" },
  { label: "Order Minimum: Low to High", field: "minimum_quantity", direction: "asc" },
  { label: "Order Minimum: High to Low", field: "minimum_quantity", direction: "desc" },
]

const defaultOption = options[0]

//region Component
export function SortMenu(props: SortMenuProps) {
  const { defaultValue = defaultOption, mobileClassName, onSelect } = props
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [value_, setValue] = useState<SortOption>(() => defaultValue)
  const sortByRef = useRef<HTMLDivElement>(null)

  const isControlled = "value" in props
  const value = isControlled ? (props.value ?? defaultOption) : value_

  const toggleMenu = () => setMenuOpen((prev) => !prev)
  const closeMenu = () => setMenuOpen(false)
  const handleSelection = (option: SortOption) => {
    if (!isControlled) setValue(option)

    onSelect?.(option)
    closeMenu()
  }

  const buttonLabel = useMemo(() => options.find((option) => optionIsEqual(option, value))?.label, [value])
  const ChevronIcon = isMenuOpen ? ChevronUpIcon : ChevronDownIcon

  return (
    <div ref={sortByRef} className="flex items-center relative text-sm pb-2">
      <Bee.Button className={twMerge("rounded-l-none sm:hidden", mobileClassName)} onClick={toggleMenu}>
        Sort
      </Bee.Button>
      <div className="hidden sm:flex"></div>
      <button className="cursor-pointer gap-1 hidden sm:flex" onClick={toggleMenu}>
        <span className="font-bold">Sort by</span>
        {buttonLabel}
        <ChevronIcon className="w-5 h-5" />
      </button>

      {isMenuOpen && (
        <>
          <SortDesktop onSelect={handleSelection} onClose={closeMenu} value={value} sortByRef={sortByRef} />
          <SortMobile onSelect={handleSelection} onClose={closeMenu} value={value} />
        </>
      )}
    </div>
  )
}
//endregion

//region Private Components
const SortDesktop = ({ onClose, onSelect, value, className, sortByRef }: SortMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // getting the event target inside Shadow DOM
      const path = event.composedPath()

      if (
        menuRef.current &&
        sortByRef?.current &&
        !path.includes(menuRef.current) &&
        !path.includes(sortByRef.current)
      ) {
        onClose?.()
      }
    }

    document.addEventListener("mousedown", handleOutsideClick)
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [onClose])

  return (
    <div
      ref={menuRef}
      className={twMerge(
        "hidden sm:flex flex-col absolute top-full items-start right-0 min-w-[219px] mt-1.5 border border-gray-200 rounded-lg shadow-lg z-50 divide-y divide-gray-200 bg-white",
        className
      )}
    >
      <Menu>
        {options.map((option) => (
          <MenuButton
            key={displaySortOption(option)}
            className="flex w-full items-center text-left px-4 py-3 text-sm hover:cursor-pointer"
            onClick={() => {
              onSelect?.(option)
              onClose?.()
            }}
          >
            {optionIsEqual(value, option) && <CheckIcon className="w-4 h-4 text-blue-600 mr-2" />}
            {option.label}
          </MenuButton>
        ))}
      </Menu>
    </div>
  )
}

const SortMobile = ({ onClose, onSelect, value = options[0] }: SortMenuProps) => {
  const [selectedValue, setSelectedValue] = useState<SortOption>(value)
  return (
    <div className={twMerge("flex flex-col sm:hidden fixed inset-0 bg-white z-50 overflow-hidden")}>
      <div className="flex justify-between px-4 py-3.5">
        <h1 className="text-lg font-bold">Sort By</h1>
        <XMarkIcon onClick={onClose} className="w-6 h-6 text-gray-600 ml-1" />
      </div>

      <hr />

      <RadioGroup className="flex flex-col pb-8 px-6 grow">
        {options.map((option) => (
          <Radio
            key={option.label}
            value={option}
            className="flex items-center py-2.5 gap-2"
            onClick={() => setSelectedValue(option)}
          >
            <div
              className={twMerge(
                "flex w-4 h-4 rounded-full",
                optionIsEqual(selectedValue, option) ? "border-navy-800 border-4" : "border border-gray-300"
              )}
            />
            {option.label}
          </Radio>
        ))}
        <div className="flex mt-auto">
          <Bee.Button
            kind="primary"
            className="w-full"
            onClick={() => {
              onSelect?.(selectedValue)
              onClose?.()
            }}
          >
            Apply
          </Bee.Button>
        </div>
      </RadioGroup>
    </div>
  )
}
//endregion

//region Private Functions
function optionIsEqual(option?: SortOption, selectedOption?: SortOption) {
  if (option == null || selectedOption == null) return false

  return option?.field === selectedOption?.field && option?.direction === selectedOption?.direction
}

function displaySortOption(option: SortOption) {
  return option?.label ?? humanize(`${option?.field} ${option?.direction}`)
}
//endregion
